app.factory('Company',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {
        create: {method: 'POST', params: {}, url: 'api/company/create'},
        edit: {method: 'POST', params: {'id':'@id'}, url: 'api/company/:id'},
        get: {method: 'GET', params: {'id':'@id'}, url: 'api/company/:id'},
        delete: {method: 'DELETE', params: {'id':'@id'}, url: 'api/company/:id'},
        list: {method: 'GET', params: {}, url: 'api/company/'},
        removeUser: {method: 'POST', params: {'id':'@id'}, url: 'api/company/:id/removeUser'},
        getFilters: {method: 'GET', params: {}, url: 'api/company/filters'},
        createFilter: {method: 'POST', params: {}, url: 'api/company/create-filter'},
        deleteFilter: {method: 'POST', params: {}, url: 'api/company/delete-filter'}
    });

    return return_object;
}]);
