app.directive("errorSpan", function() {
    return {
        restrict: 'E',
        template:'<span class="error-span" ng-show="(form.$submitted || form[field].$touched) && form[field].$error[type]">{{error}}</span>',
        replace: true,
        scope: {
            form: "=",
            field: "@",
            type: "@",
            error: "@"
        }
    }
});