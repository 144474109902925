app.factory('User',  ['$resource', 'Api', function($resource, Api) {
    var return_object = $resource(Api.getServer(), {}, {
        //checkExists: { method: 'POST', params: {}, url: Api.getServer()+ '/account/check-exists0'},
        login: {method: 'POST', params: {}, url: Api.getServer()+'/auth/login/'},
        logout: {method: 'GET', params: {}, url: Api.getServer()+'/auth/logout_old/'},

        getCurrent: {method: 'GET', params: {}, url: 'api/user/current'},
        resetPassword: {method: 'POST', params: {}, url: 'api/user/reset_password'},

        getTrainingsCurrent: {method: 'GET', params: {}, url: 'api/user/get-current-trainings'},
        getTrainingsAvailable: {method: 'GET', params: {}, url: 'api/user/get-available-trainings'},
        getTrainingsDone: {method: 'GET', params: {}, url: 'api/user/get-done-trainings'},

        create: {method: 'POST', params: {}, url: 'api/user/create'},
        edit: {method: 'POST', params: {'id':'@id'}, url: 'api/user/:id'},
        get: {method: 'GET', params: {'id':'@id'}, url: 'api/user/:id'},
        delete: {method: 'DELETE', params: {'id':'@id'}, url: 'api/user/:id'},
        list: {method: 'GET', params: {}, url: 'api/user/'},
        quick_list: {method: 'GET', params: {}, url: 'api/user/quick'},
        options: {method: 'GET', url: 'api/user/options'},

        getFilters: {method: 'GET', params: {}, url: 'api/user/filters'},
        createFilter: {method: 'POST', params: {}, url: 'api/user/create-filter'},
        deleteFilter: {method: 'POST', params: {}, url: 'api/user/delete-filter'}
    });

    return return_object;
}]);
