app.factory('Training',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {
        create: {method: 'POST', params: {}, url: 'api/training/create'},
        edit: {method: 'POST', params: {'id':'@id'}, url: 'api/training/:id'},
        get: {method: 'GET', params: {'id':'@id'}, url: 'api/training/:id'},
        delete: {method: 'DELETE', params: {'id':'@id'}, url: 'api/training/:id'},
        list: {method: 'GET', params: {}, url: 'api/training/'},

        getFilters: {method: 'GET', params: {}, url: 'api/training/filters'},
        createFilter: {method: 'POST', params: {}, url: 'api/training/create-filter'},
        deleteFilter: {method: 'POST', params: {}, url: 'api/training/delete-filter'},
        deleteImage: {method: 'POST', params: {'training': '@training', 'id':'@id'}, url: 'api/training/delete-motion-image/:training/:id'}
    });

    return return_object;
}]);
