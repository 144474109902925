app.directive("validateAccountName", ['Api', '$http', '$q', function(Api, $http, $q) {
    return {
        restrict: "A",
        require: "ngModel",
        link: function(scope, el, attrs, ngModel) {

            //var modelAccountValidator = function (value)
            //{
            //    console.log('TODO');
            //
            //    ngModel.$setValidity('validateAccount', true);
            //    return value;
            //}
            //
            //
            //ngModel.$parsers.push(modelAccountValidator);
            //
            //attrs.$observe("required", function(newval) {
            //    required = newval;
            //    modelAccountValidator(ngModel.$viewValue);
            //});

            function callback(response) {
                //if (_.isUndefined(target)) {
                //    return;
                //}

                //ngModel.$setValidity('validateAccount', response.exists);

            }
            ngModel.$asyncValidators.validateAccount = function (modelValue, viewValue) {

                var value = modelValue || viewValue;

                var deferred = $q.defer();


                $http.post( Api.getServer()+ '/account/check-exists' , {account_name: value}).then(
                    function(response) {
                        console.log(response);
                        if (response.data.account_exists) {1
                            console.log('rejected');
                            deferred.reject();
                            return;
                        }
                        console.log('not rejected');
                        deferred.resolve();
                    }
                );

                return deferred.promise;

                //return Account.checkExists({account_name: value}).$promise.then(function (response) {
                //
                //    console.log('response', response.account_exists);
                //    return response.account_exists;
                //});
            };


        }
    };
}]);