app.filter('isEmpty', function () {
        var property;
        return function (obj) {
            for (property in obj) {
                if (obj.hasOwnProperty(property)) {
                    return false;
                }
            }
            return true;
        };
    });