app.factory('Print',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {
        mailUsers: {method: 'POST', params: {}, url: Api.getServer()+'/print/user/mail/'},
        mailCompanies: {method: 'POST', params: {}, url: Api.getServer()+'/print/companies/mail/'},
        mailTrainingData: {method: 'POST', params: {}, url: Api.getServer()+'/print/training_data/mail/'},
        mailTrainer: {method: 'POST', params: {}, url: Api.getServer()+'/print/trainer/mail/'},
        mailTraining: {method: 'POST', params: {}, url: Api.getServer()+'/print/training/mail/'},
        sendEmail: {method: 'POST', params: {}, url: Api.getServer()+'/send/training_data/mail/'},
    });

    return return_object;
}]);