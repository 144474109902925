app.factory('Api',  ['$resource', function($resource) {
    var api = {
        protocol: window.location.protocol,
        host: window.location.hostname,
        port: window.location.port,
        path: 'api'
    };

    console.log(api);

    var getDomain = function() {
        return api.protocol+'//'+api.host;
    };

    return {
        getServer: function()
        {
            var base = getDomain();
            if (api.port.length > 0) {
                base += ':' + api.port;
            }
            return base + '/' + api.path;
        },
        getDomain: getDomain
    };
}]);
