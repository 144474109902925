angular.module('pluc_main').controller('MenuController', ['$scope', '$uibModal', '$timeout', 'User', function ($scope, $uibModal, $timeout, User) {
        console.log('opened menu controller');

        $scope.loading = false;

        $scope.error_messages = {};
        $scope.login_form = {};

        $scope.login = {
            'email': '',
            'password': '',
            'remember': ''
        };

        $scope.doLogin = function () {
            console.log('do login');

            $scope.error_messages = {};

            if ($scope.login_form.$valid) {
                $scope.loading = true;
                User.login($scope.login).$promise.then(function (data) {

                    console.log('login correct, redirect');
                    setTimeout(function(){
                        window.location = '/timeline';
                    }, 2000);

                }, function (data) {
                    console.log('error', data);
                    $scope.loading = false;
                    if (data.status == 422) {
                        angular.forEach(data.data, function (data, key) {
                            $scope.error_messages[key] = data;
                        });
                    }
                });
            }
        };

        $scope.showPasswordForget = function (show){
            $scope.password_mode = show;
            $scope.error_messages = {};
        };

        $scope.doPasswordForget = function () {
            console.log('do reset');

            $scope.error_messages = {};

            if ($scope.login_form.$valid) {
                $scope.loading = true;
                User.resetPassword($scope.login).$promise.then(function (data) {

                    console.log('login correct, redirect');
                    setTimeout(function(){
                        window.location = '/login';
                    }, 2000);

                }, function (data) {
                    console.log('error', data);
                    $scope.loading = false;
                    if (data.status == 422) {
                        angular.forEach(data.data, function (data, key) {
                            $scope.error_messages[key] = data;
                        });
                    }
                });
            }
        };
    }]);
