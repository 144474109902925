app.factory('Trainer',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {
        create: {method: 'POST', params: {}, url: 'api/trainer/create'},
        edit: {method: 'POST', params: {'id':'@id'}, url: 'api/trainer/:id'},
        get: {method: 'GET', params: {'id':'@id'}, url: 'api/trainer/:id'},
        list: {method: 'GET', params: {}, url: 'api/trainer/'},

        getFilters: {method: 'GET', params: {}, url: 'api/trainer/filters'},
        createFilter: {method: 'POST', params: {}, url: 'api/trainer/create-filter'},
        deleteFilter: {method: 'POST', params: {}, url: 'api/trainer/delete-filter'}
    });

    return return_object;
}]);