app.factory('Timeline',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {
        list: { method: 'GET', params: {}, url: 'api/timeline/', isArray: true },
        get: { method: 'GET', params: { id:'@id' }, url: 'api/timeline/:id' },
        create: { method: 'POST', params: {}, url: 'api/timeline/create' },
        edit: { method: 'POST', params: { id:'@id' }, url: 'api/timeline/:id' },
        delete: { method: 'DELETE', params: {id:'@id' }, url: 'api/timeline/:id' }
    });

    return return_object;
}]);
