app.factory('CheckStateChangeService', function($rootScope){

  var addCheck = function($scope){ 

    var removeListener = $rootScope.$on('$stateChangeStart'
        , function (event, toState, toParams, fromState, fromParams) {

          if($scope.form.$pristine)
          {
            return;
          }

          var shouldContinue = confirm("Er zijn velden aangepast welke nog niet zijn opgeslagen" +
                 ", weet je zeker dat je door wil gaan");
          if(shouldContinue)
          {
            return;
          }
          event.preventDefault();
    }); 

    $scope.$on("$destroy", removeListener);
  };

  return { checkFormOnStateChange : addCheck };
});