app.directive("passwordConfirmation", function() {
    return {
        require: "ngModel",
        scope: {
            otherModel: "=passwordConfirmation"
        },
        link: function (scope, element, attributes, ngModel) {

            ngModel.$validators.passwordConfirmation = function (modelValue) {
                if(modelValue == '')
                {
                    // case already handled by the 'required' attribute
                    return true;
                }
                return modelValue == scope.otherModel.$modelValue;
            };

            scope.$watch("otherModelValue", function () {
                ngModel.$validate();
            });
        }
    }
});