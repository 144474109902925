app.filter('flattenErrorMessages', function () {

        return function (error_messages) {

            var error_messages_flattened = [];

            angular.forEach(error_messages, function(error_message_holder){
                angular.forEach(error_message_holder, function(error_message){
                    error_messages_flattened.push(error_message);
                });
            });
            return error_messages_flattened;

        };
    });