angular.module('pluc_main').controller('RegisterController', ['$scope', 'User', function($scope, User){
    console.log('opened register controller');

    $scope.error_messages = {};


    $scope.user = {
        'first_name':'',
        'middle_name':'',
        'last_name':'',
        'email':'',
        'account_name':'',
        'password':'',
        'password_confirmation':''
    }


    $scope.createUser = function()
    {
        $scope.error_messages = {};

        if($scope.register_form.$valid) {

            User.create($scope.user).$promise.then(function(data){
                window.location = data.redirect_url;

            }, function(data){
                console.log('error', data);

                if(data.status == 422)
                {
                    angular.forEach(data.data, function(data, key) {
                        $scope.error_messages[key] = data;
                    });
                }
            });
        }
    }




}]);