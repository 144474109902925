app.factory('TrainingData',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {
        getCurrent: {method: 'GET', params: {}, url: '/training_data/current'},
        create: {method: 'POST', params: {}, url: 'api/training_data/create'},
        breakup: {method: 'POST', params: {'id':'@id'}, url: 'api/training_data/breakup/:id'},
        printParticipantList: {method: 'GET', params: {'id':'@id'}, url: '/print/participant-list/:id'},
        edit: {method: 'POST', params: {'id':'@id'}, url: 'api/training_data/:id'},
        get: {method: 'GET', params: {'id':'@id'}, url: 'api/training_data/:id'},
        delete: {method: 'DELETE', params: {'id':'@id'}, url: 'api/training_data/:id'},
        list: {method: 'GET', params: {}, url: 'api/training_data/'},

        enroll: {method: 'GET', params:{'training_data_id':'@training_data_id'}, url: 'api/training_data/:training_data_id/enroll'},

        addUser: {method: 'GET', params: {'id':'@id','user_id':'@user_id'}, url: 'api/training_data/:id/adduser/:user_id'},
        addInvitee: {method: 'GET', params: {'id':'@id','user_id':'@user_id','invitee':1}, url: 'api/training_data/:id/adduser/:user_id'},
        getTemplates: {method: 'GET', params: {}, url: 'api/mail_templates'},
        removeUser: {method: 'GET', params: {'id':'@id','user_id':'@user_id'}, url: 'api/training_data/:id/removeuser/:user_id'},
        addTrainer: {method: 'GET', params: {'id':'@id','trainer_id':'@trainer_id'}, url: 'api/training_data/:id/addtrainer/:trainer_id'},
        removeTrainer: {method: 'GET', params: {'id':'@id','trainer_id':'@trainer_id'}, url: 'api/training_data/:id/removetrainer/:trainer_id'},

        getFilters: {method: 'GET', params: {}, url: 'api/training_data/filters'},
        createFilter: {method: 'POST', params: {}, url: 'api/training_data/create-filter'},
        deleteFilter: {method: 'POST', params: {}, url: 'api/training_data/delete-filter'},

        setAanwezig: {method: 'POST', params: {}, url: 'api/training_data/aanwezig'},
        setFactuur: {method: 'POST', params: {}, url: 'api/training_data/factuur'},
        generateCert: {method: 'POST', params: {}, url: 'api/training_data/make_certs'},
        generateFacturen: {method: 'POST', params: {}, url: 'api/training_data/make_facturen'},
        removeCert: {method: 'POST', params: {'id':'@id'}, url: 'api/user/:id/delete_certificate'},
        removeFactuur: {method: 'POST', params: {'id':'@id'}, url: 'api/user/:id/delete_factuur'},

        saveUserPivot: {method: 'POST', params: {}, url: 'api/training_data/save_factuur_info'},
        sendFacturen: {method: 'POST', params: {}, url: 'api/training_data/send_facturen'},
        sendCertificaten: {method: 'POST', params: {}, url: 'api/training_data/send_certificaten'},

        getTrainingDataDocuments: {method: 'GET', params: {'id':'@id'}, url: 'api/training_data/:id/get-training-data-documents'},
        deleteTrainingDataDocument: {method: 'DELETE', params: {'id':'@id'}, url: 'api/training_data/delete-training-data-document'},
        duplicateTrainingDataDocument: {method: 'POST', params: {'id':'@id'}, url: 'api/training_data/duplicate-training-data-document'}
    });

    return return_object;
}]);
