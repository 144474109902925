app.factory('SavedFilter',  ['$resource', 'Api',function($resource, Api) {

    var return_object = $resource( Api.getServer() + '', {}, {

        create: {method: 'POST', params: {}, url: 'api/saved-filter/create'},
        delete: {method: 'DELETE', params: {}, url: 'api/saved-filter/'},
        getAll: {method: 'GET', params: {}, url: 'api/saved-filter/'}
    });

    return return_object;
}]);