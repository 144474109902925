var app = angular.module('pluc_main', [
    'ui.router',
    'ui.bootstrap',
    'ngResource',
]);

angular.module('pluc_main').config(function($stateProvider, $urlRouterProvider, $locationProvider) {
    // For any unmatched url, redirect to /state1
    $urlRouterProvider.otherwise("/");
    $locationProvider.html5Mode(true);
    // Now set up the states
    $stateProvider
        .state('app', {
            url: '/',
            views: {
                'main-view': {
                    controller: 'MainController',
                    templateUrl: 'views/templates/main',
                    controllerAs: 'main'
                },
                'top-menu': {
                    controller: 'MenuController',
                    templateUrl: 'views/templates/menu',
                    controllerAs: 'menu'

                }
            }
        })
        .state('app.register', {
            url: "register",
            views: {
                'main-view@': {
                    controller: 'RegisterController',
                    templateUrl: 'views/templates/register',
                    controllerAs: 'register'
                }
            }
        });
});

angular.module('pluc_main').run(function(){
});
